
import {useRouter} from "vue-router";
import useAuth from "@/modules/auth/composables/useAuth";
import {ref} from "vue";
import toastr from "toastr";

export default {
    setup() {
        const {push} = useRouter();
        const {loginUser} = useAuth()
        const userForm = ref({
            username: "",
            password: ""
        })
        const sending = ref(false)

        return {
            userForm,
            sending,
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await loginUser(userForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await push({name: 'home'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            },
            register: () => {
                push({name: 'register'})
            }
        }
    }
}
